<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Reporting Manager</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="managerFilter"
            :options="managerOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => managerSelected(val)"
            placeholder="Select By Manager.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Employee</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="employeeFilter"
            :options="employeeOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:employeeFilter', val)"
            placeholder="Select By Employee.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
            placeholder="Select Status.."
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Listing Type </label>
          <b-form-checkbox
            :checked="listType"
            name="check-button"
            switch
            @input="(val) => $emit('update:listType', val)"
          >
            {{ listType == true ? "Showing My Team" : "Showing All" }}
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Month</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="monthFilter"
            :options="monthOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:monthFilter', val)"
            placeholder="Select Month.."
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>By Year</label>
          <b-form-spinbutton
            v-model="yearFilter"
            min="2020"
            :max="new Date().getFullYear()"
          />
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <!-- basic -->
          <b-form-group
            :label="'By OKR Completion Percentage - ' + completionValue + '%'"
            label-for="completion"
          >
            <b-form-input
              type="range"
              min="0"
              max="100"
              v-model="completionValue"
              id="completion"
              name="completion"
              @input="(val) => $emit('update:completionValue', val)"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-primary"
            @click="reset"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    statusFilter: {},
    statusOptions: {
      type: Array,
      required: true,
    },
    employeeFilter: {},
    employeeOptions: {
      type: Array,
      required: true,
    },
    managerFilter: {},
    managerOptions: {
      type: Array,
      required: true,
    },
    monthFilter: {},
    monthOptions: {
      type: Array,
      required: true,
    },
    yearFilter: {},
    listType: {
      type: Boolean,
    },
    completionValue: {
      type: Number,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentState: true,
      completion_value: 0,
    };
  },
  methods: {
    reset() {
      // this.statusFilter = null;
      // this.employeeFilter = null;
      // this.managerFilter = null;
      // this.monthFilter = null;
      // this.yearFilter = null;
      // this.listType = false;
      // this.completionValue = 0;

      this.$emit("update:statusFilter", null);
      this.$emit("update:employeeFilter", null);
      this.$emit("update:managerFilter", null);
      this.$emit("update:monthFilter", null);
      this.$emit("update:yearFilter", null);
      this.$emit("update:listType", false);
      this.$emit("update:completionValue", 0);
    },
    managerSelected(val) {
      this.$emit("update:managerFilter", val);
      console.log(val);
      if (val == null) {
        this.fetchEmployeesList();
      } else {
        this.fetchTeamMates(val);
      }
    },
    fetchEmployeesList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.$emit("update:employeeOptions", res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchTeamMates(manager_id) {
      var query = { manager_id: manager_id };
      this.$store
        .dispatch("app/fetchTeamMates", query)
        .then((res) => {
          this.$emit("update:employeeOptions", res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
